import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { delay } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { MathUtils } from 'src/app/_metronic/core/utils/math.utils';
import { MessageService } from 'src/app/_metronic/shared/services/message.service';
import { ApiResponse } from 'src/app/_metronic/shared/models/api-response';
import { environment } from 'src/environments/environment';
import { ProductDto } from '../_dto/product-dto';
import { IProductsService } from './products-service-interface';

const API_USERS_URL = `${environment.apiUrl}/products`;

@Injectable({
  providedIn: 'root',
})
export class FakeProductsService implements IProductsService {
  constructor(private http: HttpClient,
              private translate: TranslateService,
              private message: MessageService) { }

  getProductData(type: string): Observable<ApiResponse<ProductDto>>{
    let data = new ProductDto();
    data.type = type;
    data.data = {
      a: 1,
      b: 2
    };

    return of({
      message: 'Dane produktu pobrane pomyślnie.',
      status: 0,
      data: data
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  saveProductData(data: ProductDto): Observable<ApiResponse>{
    return of({
      message: 'Dane produktu zapisane.',
      status: 0,
    }).pipe(
      delay(this.getRandomResponseDelay())
    );
  }

  private getRandomResponseDelay(): number {
    return MathUtils.getRandomInt(100, 500);
  }

}
