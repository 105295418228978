import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IProductsService } from './products-service-interface';
import { AppConfig, APP_CONFIG } from 'src/app/app-config';
import { ApiResponse } from 'src/app/_metronic/shared/models/api-response';
import { ProductDto } from '../_dto/product-dto';

@Injectable({
  providedIn: 'root',
})
export class ProductsService implements IProductsService{

  private readonly url: string;

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
    this.url = `${this.config.apiUrl}/products`
  }

  getProductData(type: string): Observable<ApiResponse<ProductDto>> {
    return this.http.post<ApiResponse<ProductDto>>(`${this.url}/get-details`, { type });
  }

  saveProductData(data: ProductDto): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.url}/save-details`,  data );
  }

}
