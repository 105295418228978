import { AbstractControl, ValidatorFn } from "@angular/forms";

export function inputLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const actualLength = control.value?.toString()?.length;
    const isInvalid = actualLength > maxLength;

    if (isInvalid) {
      return { maxLength, actualLength }
    }

    return null;
  };
}
